/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiKey { 
    /**
     * The id of the associated account.
     */
    account_id: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * Time at which the key was last used to authenticate with the API.
     */
    last_used?: string;
    /**
     * The string that you assign to describe the resource. It defaults to \'`type` Key\' (e.g. \'Secret Key\' and \'Publishable Key\')
     */
    name: string;
    /**
     * The list of scopes to define the access for this key. You may specify `[\'*\']` to enable all scopes, except those that require explicit selection.
     */
    scopes: Array<ApiKey.ScopesEnum>;
    /**
     * The type of API key.  `secret` can be used to authenticate all endpoints. `publishable` is used in your frontend application and can only access a handful of endpoints (e.g. confirm a Payment Intent).
     */
    type: ApiKey.TypeEnum;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
    /**
     * The actual value of the API key to be included in the `tilled-api-key` header for authentication. `secret` keys will have a redacted value except when initially created.
     */
    value: string;
}
export namespace ApiKey {
    export type ScopesEnum = '*' | 'accounts:delete' | 'accounts:read' | 'accounts:write' | 'api_keys:read' | 'api_keys:write' | 'apple_pay_domains:read' | 'apple_pay_domains:write' | 'auth_links:read' | 'auth_links:write' | 'balance_transactions:read' | 'bank_accounts:read' | 'bank_accounts:write' | 'charges:read' | 'checkout_sessions:read' | 'checkout_sessions:write' | 'customers:read' | 'customers:write' | 'disputes:read' | 'disputes:write' | 'events:read' | 'files:read' | 'files:write' | 'onboarding:read' | 'onboarding:write' | 'outbound_transfers:read' | 'outbound_transfers:write' | 'payment_intents:read' | 'payment_intents:write' | 'payment_methods:read' | 'payment_methods:write' | 'payouts:read' | 'payouts:write' | 'platform_fees:read' | 'pricing_templates:read' | 'pricing_templates:write' | 'product_codes:read' | 'refunds:read' | 'refunds:write' | 'report_runs:read' | 'report_runs:write' | 'subscriptions:read' | 'subscriptions:write' | 'terminal_readers:read' | 'terminal_readers:write' | 'user_invitations:read' | 'user_invitations:write' | 'users:read' | 'users:write' | 'webhook_endpoints:read' | 'webhook_endpoints:write';
    export const ScopesEnum = {
        STAR: '*' as ScopesEnum,
        ACCOUNTS_DELETE: 'accounts:delete' as ScopesEnum,
        ACCOUNTS_READ: 'accounts:read' as ScopesEnum,
        ACCOUNTS_WRITE: 'accounts:write' as ScopesEnum,
        API_KEYS_READ: 'api_keys:read' as ScopesEnum,
        API_KEYS_WRITE: 'api_keys:write' as ScopesEnum,
        APPLE_PAY_DOMAINS_READ: 'apple_pay_domains:read' as ScopesEnum,
        APPLE_PAY_DOMAINS_WRITE: 'apple_pay_domains:write' as ScopesEnum,
        AUTH_LINKS_READ: 'auth_links:read' as ScopesEnum,
        AUTH_LINKS_WRITE: 'auth_links:write' as ScopesEnum,
        BALANCE_TRANSACTIONS_READ: 'balance_transactions:read' as ScopesEnum,
        BANK_ACCOUNTS_READ: 'bank_accounts:read' as ScopesEnum,
        BANK_ACCOUNTS_WRITE: 'bank_accounts:write' as ScopesEnum,
        CHARGES_READ: 'charges:read' as ScopesEnum,
        CHECKOUT_SESSIONS_READ: 'checkout_sessions:read' as ScopesEnum,
        CHECKOUT_SESSIONS_WRITE: 'checkout_sessions:write' as ScopesEnum,
        CUSTOMERS_READ: 'customers:read' as ScopesEnum,
        CUSTOMERS_WRITE: 'customers:write' as ScopesEnum,
        DISPUTES_READ: 'disputes:read' as ScopesEnum,
        DISPUTES_WRITE: 'disputes:write' as ScopesEnum,
        EVENTS_READ: 'events:read' as ScopesEnum,
        FILES_READ: 'files:read' as ScopesEnum,
        FILES_WRITE: 'files:write' as ScopesEnum,
        ONBOARDING_READ: 'onboarding:read' as ScopesEnum,
        ONBOARDING_WRITE: 'onboarding:write' as ScopesEnum,
        OUTBOUND_TRANSFERS_READ: 'outbound_transfers:read' as ScopesEnum,
        OUTBOUND_TRANSFERS_WRITE: 'outbound_transfers:write' as ScopesEnum,
        PAYMENT_INTENTS_READ: 'payment_intents:read' as ScopesEnum,
        PAYMENT_INTENTS_WRITE: 'payment_intents:write' as ScopesEnum,
        PAYMENT_METHODS_READ: 'payment_methods:read' as ScopesEnum,
        PAYMENT_METHODS_WRITE: 'payment_methods:write' as ScopesEnum,
        PAYOUTS_READ: 'payouts:read' as ScopesEnum,
        PAYOUTS_WRITE: 'payouts:write' as ScopesEnum,
        PLATFORM_FEES_READ: 'platform_fees:read' as ScopesEnum,
        PRICING_TEMPLATES_READ: 'pricing_templates:read' as ScopesEnum,
        PRICING_TEMPLATES_WRITE: 'pricing_templates:write' as ScopesEnum,
        PRODUCT_CODES_READ: 'product_codes:read' as ScopesEnum,
        REFUNDS_READ: 'refunds:read' as ScopesEnum,
        REFUNDS_WRITE: 'refunds:write' as ScopesEnum,
        REPORT_RUNS_READ: 'report_runs:read' as ScopesEnum,
        REPORT_RUNS_WRITE: 'report_runs:write' as ScopesEnum,
        SUBSCRIPTIONS_READ: 'subscriptions:read' as ScopesEnum,
        SUBSCRIPTIONS_WRITE: 'subscriptions:write' as ScopesEnum,
        TERMINAL_READERS_READ: 'terminal_readers:read' as ScopesEnum,
        TERMINAL_READERS_WRITE: 'terminal_readers:write' as ScopesEnum,
        USER_INVITATIONS_READ: 'user_invitations:read' as ScopesEnum,
        USER_INVITATIONS_WRITE: 'user_invitations:write' as ScopesEnum,
        USERS_READ: 'users:read' as ScopesEnum,
        USERS_WRITE: 'users:write' as ScopesEnum,
        WEBHOOK_ENDPOINTS_READ: 'webhook_endpoints:read' as ScopesEnum,
        WEBHOOK_ENDPOINTS_WRITE: 'webhook_endpoints:write' as ScopesEnum
    };
    export type TypeEnum = 'publishable' | 'secret';
    export const TypeEnum = {
        PUBLISHABLE: 'publishable' as TypeEnum,
        SECRET: 'secret' as TypeEnum
    };
}


