<div class="download-icon-container absolute top-[142px] ml-auto sm:right-15 sm:pl-0">
  <button mat-icon-button [matMenuTriggerFor]="customColumnMenu">
    <mat-icon
      id="customizeColumns"
      data-test-id="payouts-custom-columns-button"
      matTooltip="Customize table columns"
      [svgIcon]="'heroicons_outline:cog-8-tooth'"
    ></mat-icon>
  </button>
  <mat-menu
    #customColumnMenu="matMenu"
    yPosition="below"
    [class]="
      'bg-card relative -ml-0 mt-4 min-w-fit overflow-visible rounded-[10px] border border-gray-300 p-4 py-2 shadow-none'
    "
    ><app-custom-column [updateTableCallback]="updateTableCallback" [inputColumns]="customColumns"></app-custom-column>
  </mat-menu>
  <button
    mat-icon-button
    [disabled]="(payoutsCount$ | async) === 0 || !reportDownloadEnabled"
    (click)="downloadPayoutReport()"
    class="pl-2"
    [ngClass]="
      (payoutsCount$ | async) === 0 || !reportDownloadEnabled
        ? '[&>mat-icon>svg]:text-hint'
        : '[&>mat-icon>svg]:text-tilled-primary'
    "
  >
    <mat-icon
      id="downloadReport"
      data-test-id="payouts-overview-download-report"
      [matTooltip]="reportDownloadTooltip"
      [svgIcon]="'heroicons_outline:arrow-down-tray'"
    ></mat-icon>
  </button>
</div>
<div class="relative">
  <tilled-table
    [data]="(payoutsViewModel$ | async)!"
    [getPageCallback]="getPayouts"
    [getClickCallback]="rowClickedCallback"
    [dataLength]="noData ? 0 : (payoutsCount$ | async)"
    data-test-id="payouts-table"
    [noDataMainText]="'No payout data available'"
    [noDataSecondaryText]="''"
    [hideColumnKeys]="hideColumnKeys"
    [sortColumnKeys]="sortColumnKeys"
    [startingPageIndex]="pageIndex"
    [startingPageLimit]="pageSize"
    [scrollable]="true"
  ></tilled-table>
</div>
