<ng-container *ngIf="groupName && !copyToClipboard && !merchantApp" [formGroupName]="groupName">
  <div class="w-full pb-2.5 pt-4" *ngIf="tilledLabel">
    <tilled-label-l1>{{ sentenceCaseTitle ? (label | sentenceCase) : label }}</tilled-label-l1>
  </div>
  <mat-form-field
    [appearance]="appearance"
    class="block w-full overflow-visible"
    [hideRequiredMarker]="hideRequiredMarker"
  >
    <mat-label *ngIf="!tilledLabel" class="text-xl {{ styles }}">{{ label }}</mat-label>
    <span matPrefix *ngIf="showCurrency" class="{{ styles }}" id="currency-prefix">$&nbsp;</span>
    <input
      #nativeInput
      [class]="tilledLabel ? 'w-full tracking-normal text-tilled-primary' : styles"
      type="{{ fieldType }}"
      [formControlName]="name"
      matInput
      [allowOnlyNumbers]="showCurrency || showPercent"
      placeholder="{{ sentenceCasePlaceholder ? (placeholder | sentenceCase) : placeholder }}"
      mask="{{ mask }}"
      [suffix]="showPercent ? '%' : ''"
      (blur)="onInputBlur()"
      (focus)="onInputFocus()"
      (focusout)="isFocused = false"
      [dropSpecialCharacters]="dropMaskCharacters"
      [leadZeroDateTime]="leadZeroDateTime"
      thousandSeparator="{{ thousandSeparator }}"
      [readonly]="readonly"
      minlength="{{ minLength }}"
      maxlength="{{ maxLength }}"
      min="{{ minValue }}"
      max="{{ maxValue }}"
    />
    <ng-container *ngIf="prefixOptions && prefixControlName">
      <tilled-select
        [options]="prefixOptions"
        [placeholder]="prefixPlaceholder"
        [controlName]="prefixControlName"
        matPrefix="true"
        [errors]="errors"
      >
      </tilled-select>
    </ng-container>
    <mat-icon
      *ngIf="toolTip && !showClear! && !errors"
      class="text-hint icon-size-4.5"
      matTooltipClass="tooltip-list"
      [matTooltip]="toolTip"
      [svgIcon]="'mat_outline:help_outline'"
    >
    </mat-icon>
    <mat-error color="warn" *ngIf="errors" class="mb-0.5 pt-2">{{ errorMessage }}</mat-error>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="!groupName && !copyToClipboard && !merchantApp">
  <div class="w-full pb-2.5 pt-4" *ngIf="tilledLabel">
    <tilled-label-l1>{{
      sentenceCaseTitle ? (label === 'URL' ? label : (label | sentenceCase)) : label
    }}</tilled-label-l1>
  </div>

  <mat-form-field
    [appearance]="appearance"
    class="block w-full overflow-visible"
    [hideRequiredMarker]="hideRequiredMarker"
    subscriptSizing="dynamic"
  >
    <mat-label *ngIf="!tilledLabel" class="text-xl {{ styles }}">{{ label }}</mat-label>
    <span matPrefix *ngIf="showCurrency" class="{{ styles }}" id="currency-prefix">$&nbsp;</span>
    <div class="flex w-full items-center">
      <ng-container *ngIf="prefixOptions && prefixControlName">
        <tilled-select
          [options]="prefixOptions"
          [placeholder]="prefixPlaceholder"
          [controlName]="prefixControlName"
          [errors]="errors"
          matPrefix="true"
          class="-ml-5"
          [suffixReadonly]="suffixReadonly"
        >
        </tilled-select>
      </ng-container>
      <span
        *ngIf="prefix"
        [class]="
          (name === 'phone' ? 'pr-1.5' : 'pr-0.5') +
          (errors ? ' text-warn' : name === 'phone' ? ' text-tilled-primary' : ' text-tilled-secondary')
        "
        >{{ prefix }}</span
      >
      <div
        *ngIf="(prefixSuffixBorder === 'prefix' && prefix) || (prefixSuffixBorder === 'both' && prefix)"
        [class]="errors ? 'mx-2 h-12 border-r border-r-warn' : 'border-r-tilled-hint mx-2 h-12 border-r'"
      ></div>
      <mat-icon
        *ngIf="name === 'email'"
        [svgIcon]="'heroicons_outline:envelope'"
        class="mr-2"
        [color]="errors ? 'warn' : 'secondary'"
      ></mat-icon>
      <input
        #nativeInput
        [class]="tilledLabel ? 'w-full tracking-normal text-tilled-primary' : styles"
        type="{{ fieldType }}"
        [formControlName]="name"
        matInput
        [allowOnlyNumbers]="showCurrency || showPercent"
        placeholder="{{ sentenceCasePlaceholder ? (placeholder | sentenceCase) : placeholder }}"
        mask="{{ mask }}"
        [suffix]="showPercent ? '%' : ''"
        (blur)="onInputBlur()"
        (focus)="onInputFocus()"
        (focusout)="isFocused = false"
        [dropSpecialCharacters]="dropMaskCharacters"
        [leadZeroDateTime]="leadZeroDateTime"
        thousandSeparator="{{ thousandSeparator }}"
        [readonly]="readonly"
        minlength="{{ minLength }}"
        maxlength="{{ maxLength }}"
        min="{{ minValue }}"
        max="{{ maxValue }}"
        [required]="required"
      />
      <mat-icon
        *ngIf="errors && errorMessage"
        color="warn"
        class="icon-size-4.5"
        matTooltipClass="tooltip-list"
        [matTooltip]="errorMessage"
        [svgIcon]="'heroicons_outline:information-circle'"
      >
      </mat-icon>
      <div
        *ngIf="(prefixSuffixBorder === 'suffix' && suffix) || (prefixSuffixBorder === 'both' && suffix)"
        [class]="errors ? 'mx-1 h-12 border-l border-l-warn' : 'border-l-tilled-hint mx-1 h-12 border-l'"
      ></div>
      <span *ngIf="suffix" class="ml-2 text-tilled-secondary">{{ suffix }}</span>
      <ng-container *ngIf="showCurrency && suffixOptions">
        <tilled-select
          [options]="suffixOptions"
          [placeholder]="suffixPlaceholder"
          [controlName]="suffixControlName"
          [templateRef]="'currencySelect'"
          [errors]="errors"
          matSuffix="true"
          [suffixReadonly]="suffixReadonly"
        >
        </tilled-select>
      </ng-container>
    </div>
    <mat-icon
      *ngIf="toolTip && !showClear! && !errors"
      class="text-hint icon-size-4.5"
      matTooltipClass="tooltip-list"
      [matTooltip]="toolTip"
      [svgIcon]="'mat_outline:help_outline'"
    >
    </mat-icon>
    <mat-error color="warn" *ngIf="errors" class="mb-0.5 pt-2">{{ errorMessage }}</mat-error>
    <mat-hint id="field-hint" class="mb-0.5 pt-1" *ngIf="hint && !errors">{{ hint }}</mat-hint>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="copyToClipboard">
  <div class="w-full pb-2.5 pt-4" *ngIf="tilledLabel">
    <tilled-label-l1>{{
      sentenceCaseTitle ? (label === 'URL' ? label : (label | sentenceCase)) : label
    }}</tilled-label-l1>
  </div>
  <div class="flex flex-row">
    <div class="w-full items-center">
      <mat-form-field
        [appearance]="appearance"
        class="block w-full overflow-visible"
        [hideRequiredMarker]="hideRequiredMarker"
      >
        <mat-label *ngIf="!tilledLabel" class="text-xl {{ styles }}">{{ label }}</mat-label>
        <div class="flex w-full items-center">
          <span
            *ngIf="prefix"
            [ngClass]="
              (name === 'phone' ? 'pr-1.5' : 'pr-0.5') +
              (errors ? ' text-warn' : name === 'phone' ? ' text-tilled-primary' : ' text-tilled-secondary')
            "
            >{{ prefix }}</span
          >
          <div
            *ngIf="(prefixSuffixBorder === 'prefix' && prefix) || (prefixSuffixBorder === 'both' && prefix)"
            [class]="errors ? 'mx-2 h-12 border-r border-r-warn' : 'border-r-tilled-hint mx-2 h-12 border-r'"
          ></div>
          <mat-icon
            *ngIf="name === 'email'"
            [svgIcon]="'heroicons_outline:envelope'"
            class="mr-2"
            [color]="errors ? 'warn' : 'secondary'"
          ></mat-icon>
          <input
            #nativeInput
            [class]="tilledLabel ? 'w-full tracking-normal text-tilled-primary' : styles"
            type="{{ fieldType }}"
            [formControlName]="name"
            matInput
            [allowOnlyNumbers]="showCurrency || showPercent"
            placeholder="{{ sentenceCasePlaceholder ? (placeholder | sentenceCase) : placeholder }}"
            mask="{{ mask }}"
            [suffix]="showPercent ? '%' : ''"
            (blur)="onInputBlur()"
            (focus)="onInputFocus()"
            (focusout)="isFocused = false"
            [dropSpecialCharacters]="dropMaskCharacters"
            [leadZeroDateTime]="leadZeroDateTime"
            thousandSeparator="{{ thousandSeparator }}"
            minlength="{{ minLength }}"
            maxlength="{{ maxLength }}"
            min="{{ minValue }}"
            max="{{ maxValue }}"
            [required]="required"
          />
          <mat-icon
            *ngIf="errors && errorMessage"
            color="warn"
            class="icon-size-4.5"
            matTooltipClass="tooltip-list"
            [matTooltip]="errorMessage"
            [svgIcon]="'heroicons_outline:information-circle'"
          >
          </mat-icon>
        </div>
        <mat-error color="warn" *ngIf="errors" class="mb-0.5 pt-2">{{ errorMessage }}</mat-error>
        <mat-hint class="mb-0.5 pt-1" *ngIf="hint && !errors">{{ hint }}</mat-hint>
      </mat-form-field>
    </div>
    <div
      class="copy-link-btn hoverable flex max-h-[49.8px] items-center justify-center rounded-r-full border px-3"
      [disabled]="readonly"
      data-test-id="copy-url-button"
      [ngClass]="
        errors
          ? 'border-warn'
          : readonly
            ? 'border-tilled-neutral-400'
            : 'cursor-pointer border-tilled-primary hover:bg-primary-100'
      "
      (click)="onCopyClick('copy')"
    >
      <mat-icon
        class="notranslate mat-icon-no-color mat-icon mr-1 align-[-1px]"
        [ngClass]="errors ? 'text-warn' : 'text-secondary'"
        [svgIcon]="'heroicons_outline:square-2-stack'"
      ></mat-icon>
      <tilled-paragraph-p3 [ngClass]="errors ? 'text-warn' : ''" secondary="true">{{
        copyClickText
      }}</tilled-paragraph-p3>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="merchantApp">
  <div
    class="input-container flex w-full flex-row"
    [ngClass]="{
      'items-center': errors,
      'items-end': !errors
    }"
  >
    <div
      class="field-container flex flex-col"
      [attr.style]="windowWidth >= 1280 ? 'width: 50vw' : 'width: 90vw'"
      [ngClass]="{
        'mr-4': windowWidth < 900
      }"
    >
      <div class="w-10/12 pb-2.5 pt-4" *ngIf="tilledLabel">
        <tilled-label-l1>{{
          sentenceCaseTitle ? (label === 'URL' ? label : (label | sentenceCase)) : label
        }}</tilled-label-l1>
      </div>

      <mat-form-field
        [appearance]="appearance"
        class="mr-8 flex overflow-visible"
        [ngClass]="{
          'w-11/12': windowWidth >= 1280
        }"
        [hideRequiredMarker]="hideRequiredMarker"
      >
        <mat-label *ngIf="!tilledLabel" class="text-xl {{ styles }}">{{ label }}</mat-label>
        <span matPrefix *ngIf="showCurrency" class="{{ styles }}" id="currency-prefix">$&nbsp;</span>
        <div class="flex w-full items-center">
          <ng-container *ngIf="prefixOptions && prefixControlName">
            <tilled-select
              [options]="prefixOptions"
              [placeholder]="prefixPlaceholder"
              [controlName]="prefixControlName"
              [errors]="errors"
              matPrefix="true"
              class="-ml-5"
              [suffixReadonly]="suffixReadonly"
            >
            </tilled-select>
          </ng-container>
          <span
            *ngIf="prefix"
            [class]="
              (name === 'phone' ? 'pr-1.5' : 'pr-0.5') +
              (errors ? ' text-warn' : name === 'phone' ? ' text-tilled-primary' : ' text-tilled-secondary')
            "
            >{{ prefix }}</span
          >
          <div
            *ngIf="(prefixSuffixBorder === 'prefix' && prefix) || (prefixSuffixBorder === 'both' && prefix)"
            [class]="errors ? 'mx-2 h-12 border-r border-r-warn' : 'border-r-tilled-hint mx-2 h-12 border-r'"
          ></div>
          <mat-icon
            *ngIf="name === 'email'"
            [svgIcon]="'heroicons_outline:envelope'"
            class="mr-2"
            [color]="errors ? 'warn' : 'secondary'"
          ></mat-icon>
          <input
            #nativeInput
            [class]="tilledLabel ? 'w-full tracking-normal text-tilled-primary' : styles"
            type="{{ fieldType }}"
            [formControlName]="name"
            matInput
            [allowOnlyNumbers]="showCurrency || showPercent"
            placeholder="{{ sentenceCasePlaceholder ? (placeholder | sentenceCase) : placeholder }}"
            mask="{{ mask }}"
            [suffix]="showPercent ? '%' : ''"
            (blur)="onInputBlur()"
            (focus)="onInputFocus()"
            (focusout)="isFocused = false"
            [dropSpecialCharacters]="dropMaskCharacters"
            [leadZeroDateTime]="leadZeroDateTime"
            thousandSeparator="{{ thousandSeparator }}"
            [readonly]="readonly"
            minlength="{{ minLength }}"
            maxlength="{{ maxLength }}"
            min="{{ minValue }}"
            max="{{ maxValue }}"
            [required]="required"
          />
          <div
            *ngIf="(prefixSuffixBorder === 'suffix' && suffix) || (prefixSuffixBorder === 'both' && suffix)"
            [class]="errors ? 'mx-1 h-12 border-l border-l-warn' : 'border-l-tilled-hint mx-1 h-12 border-l'"
          ></div>
          <span *ngIf="suffix" class="ml-2 text-tilled-secondary">{{ suffix }}</span>
          <ng-container *ngIf="showCurrency && suffixOptions">
            <tilled-select
              [options]="suffixOptions"
              [placeholder]="suffixPlaceholder"
              [controlName]="suffixControlName"
              [templateRef]="'currencySelect'"
              [errors]="errors"
              matSuffix="true"
              [suffixReadonly]="suffixReadonly"
            >
            </tilled-select>
          </ng-container>
        </div>
        <mat-icon
          *ngIf="toolTip && !showClear! && !errors"
          class="text-hint icon-size-4.5"
          matTooltipClass="tooltip-list"
          [matTooltip]="toolTip"
          [svgIcon]="'mat_outline:help_outline'"
        >
        </mat-icon>
        <mat-error color="warn" *ngIf="errors" class="mb-0.5 pt-2">{{ errorMessage }}</mat-error>
        <mat-hint id="field-hint" class="mb-0.5 pt-1" *ngIf="hint && !errors && windowWidth <= 767 && isFocused">{{
          hint
        }}</mat-hint>
      </mat-form-field>
    </div>
    <div
      class="mr-6 flex h-13"
      *ngIf="windowWidth > 767"
      [ngClass]="{
        'items-end': errors,
        'items-center': !errors
      }"
    >
      <div
        class="tooltipSideDiv flex"
        [ngClass]="{
          'w-[35vw] pl-12': windowWidth <= 767,
          'w-[32vw] pr-5': windowWidth > 767
        }"
      >
        <mat-hint
          *ngIf="hint && isFocused"
          class="font-openSans text-base leading-5 text-tilled-primary"
          [ngClass]="{
            'ml-4': windowWidth <= 1280,
            'mr-12': windowWidth < 845 && windowWidth > 767,
            'pt-1': !errors
          }"
          >{{ hint }}</mat-hint
        >
      </div>
    </div>
  </div>
</ng-container>
