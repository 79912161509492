/**
 * Tilled Internal API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountSettings } from './accountSettings';
import { AccountDocument } from './accountDocument';
import { BusinessProfile } from './businessProfile';
import { TerminalReader } from './terminalReader';
import { BankAccount } from './bankAccount';
import { InternalAccountCapability } from './internalAccountCapability';
import { AccountInternalMetadata } from './accountInternalMetadata';


export interface InternalAccount { 
    /**
     * Bank accounts attached to this account. Primarily used for payouts.
     */
    bank_accounts: Array<BankAccount>;
    business_profile?: BusinessProfile;
    capabilities?: Array<InternalAccountCapability>;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Documents that have been requested for an account, which are either in the `requested` or `submitted` status
     */
    document_requests: Array<AccountDocument>;
    /**
     * The primary user\'s email address.
     */
    email?: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    internal_metadata?: AccountInternalMetadata;
    /**
     * Whether Tilled is providing support for this account.
     * @deprecated
     */
    merchant_support?: boolean;
    /**
     * Set of [key-value pairs](#section/Metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format.
     */
    metadata?: { [key: string]: string; };
    /**
     * The business name or individual name.
     */
    name?: string;
    partner_account_name?: string;
    reseller_account_name?: string;
    /**
     * The account settings object.
     */
    settings?: AccountSettings;
    /**
     * The status of the account.
     */
    status?: InternalAccount.StatusEnum;
    /**
     * Terminal Readers attached to this account.
     */
    terminal_readers: Array<TerminalReader>;
    /**
     * The Tilled account type. Can be `partner` or `merchant`.
     */
    type: InternalAccount.TypeEnum;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
    /**
     * The `partner` white label domain.
     */
    white_label_domain?: string;
}
export namespace InternalAccount {
    export type StatusEnum = 'active' | 'requires_pricing' | 'funding_hold' | 'account_review' | 'partially_active' | 'action_required' | 'created' | 'started' | 'disabled' | 'rejected' | 'withdrawn' | 'onboarding';
    export const StatusEnum = {
        ACTIVE: 'active' as StatusEnum,
        REQUIRES_PRICING: 'requires_pricing' as StatusEnum,
        FUNDING_HOLD: 'funding_hold' as StatusEnum,
        ACCOUNT_REVIEW: 'account_review' as StatusEnum,
        PARTIALLY_ACTIVE: 'partially_active' as StatusEnum,
        ACTION_REQUIRED: 'action_required' as StatusEnum,
        CREATED: 'created' as StatusEnum,
        STARTED: 'started' as StatusEnum,
        DISABLED: 'disabled' as StatusEnum,
        REJECTED: 'rejected' as StatusEnum,
        WITHDRAWN: 'withdrawn' as StatusEnum,
        ONBOARDING: 'onboarding' as StatusEnum
    };
    export type TypeEnum = 'partner' | 'merchant' | 'reseller';
    export const TypeEnum = {
        PARTNER: 'partner' as TypeEnum,
        MERCHANT: 'merchant' as TypeEnum,
        RESELLER: 'reseller' as TypeEnum
    };
}


