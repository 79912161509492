import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ComponentBase } from 'app/core/componentBase';
import { CustomColumn } from 'app/core/models/custom-column';
import { FormCardComponent } from 'app/shared/cards/form-cards/form-card.component';
import { TilledInputComponent } from 'app/shared/form-fields/tilled-input/tilled-input.component';
import { TilledParagraphP3Component } from 'app/shared/tilled-text';

@Component({
  selector: 'app-custom-column',
  templateUrl: './custom-column.component.html',
  styleUrls: ['./custom-column.component.scss'],
  standalone: true,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  imports: [
    CdkDrag,
    CdkDropList,
    CdkDropListGroup,
    CommonModule,
    FormCardComponent,
    FormsModule,
    MatCheckboxModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    TilledInputComponent,
    TilledParagraphP3Component,
  ],
})
export class CustomColumnComponent extends ComponentBase implements OnInit {
  @Input() inputColumns: CustomColumn[];
  @Input() isMetaData: boolean = false;
  @Input() updateTableCallback: (columns: CustomColumn[]) => void;
  @Output() outputColumns: CustomColumn[];
  public isLoading: boolean;
  public customColumnForm: FormGroup;
  public active: CustomColumn[] = [];
  public available: CustomColumn[] = [];
  public metaData: CustomColumn[] = []; //future use

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.active = this.inputColumns?.filter((c) => c.active);
    this.available = this.inputColumns?.filter((c) => !c.active);
  }

  drop(event: CdkDragDrop<CustomColumn[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      if (event.container.id === 'activeColumns') {
        event.previousContainer.data[event.previousIndex].active = true;
      } else {
        event.previousContainer.data[event.previousIndex].active = false;
      }
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    if (this.updateTableCallback) {
      this.updateTableCallback([...this.active, ...this.available, ...this.metaData]);
    }
  }

  move(currentContainer: string, currentIndex: number): void {
    //TODO will need to consider breaking available columns into 2 list when metadata is used
    if (currentContainer === 'availableColumns') {
      //set to active, and move to active array
      this.available[currentIndex].active = true;
      transferArrayItem(this.available, this.active, currentIndex, this.active.length);
    } else {
      //set to inactive, and move to available array
      this.active[currentIndex].active = false;
      transferArrayItem(this.active, this.available, currentIndex, this.available.length);
    }
    if (this.updateTableCallback) {
      this.updateTableCallback([...this.active, ...this.available, ...this.metaData]);
    }
  }
}
